
const moneyWinConfig = {
    API_URL: process.env.REACT_APP_MW_API_URL,
    API_KEY: process.env.REACT_APP_MW_API_KEY,
    ENVIRONMENT: "TEST",

    CG_PROXY: "cg-proxy",
    SA_PROXY: "sa-proxy",
    SP_API: "sp-api",
    SA_API: "sa-api",

    GET_USERS : "get-users",
    AUTHENTICATE_LOGIN : "authenticate-login",
    GET_USER_INFO : "get-user-info",
    ADD_USER : "add-user",
    ADD_CHIPS: "add-chips",
    LOGOUT_USER : "logout-user",
    GET_USER_BALANCE : "get-user-balance",
    GET_DASHBOARD_DATA : "get-dashboard-data",
    GET_MANAGED_USERS : "get-managed-users",
    GET_MANAGED_USERS_BETTING : "get-managed-users-betting",
    GENERATE_REFERRAL : "generate-referral",
    CASHIN_REQUEST : "cashin-request",
    CASHOUT_REQUEST : "cashout-request",
    GET_TRANSACTION_REQUESTS : "get-transaction-requests",
    GET_TRANSACTION_RECORD : "get-transaction-record",
    PROCESS_CASHIN_REQUEST : "accept-cashin-request",
    GET_BETTING_HISTORY : "get-betting-history",
    EVO_PROXY : "evo-proxy",
    EVO_AUTHENTICATE : "evo-auth",
    GET_DEDUCTIONS: "get-deductions",
    MODIFY_AGENT_PERCENTAGE: "modify-agent-percentage",
    GET_REMAINING_PERCENTAGE: "get-remaining-percentage",
    GET_PROFILE_INFO: "get-profile-info",
    UPDATE_PROFILE_INFO: "update-profile-info",
    UPDATE_PROFILE_PICTURE: "update-profile-picture",
    SIGN_SIGNATURE: "test-api",
    PAYCOOLS_PROXY: "paycools-proxy",
    UPDATE_PASSWORD: "update-password",
    GET_GAME_ICON: "get-game-icon",
    GET_CHIPS_INFO: "get-chips-info",
    UPDATE_CASHOUT_REQUEST: "update-cashout-request",
    GET_TOP_LEVEL: "get-top-level",
    GET_VALID_BETS: "get-valid-bets",
    GET_UPLINE: "get-upline",
    MODE_OF_PAYMENT: "mode-of-payment",
    SEND_EMAIL: "send-email",
    UPDATE_MOBILE_VERIFICATION: "update-verification",
    SEND_USER_CONTACT_MESSAGE: "send-user-contact-message",
    GET_AGENT_SETTINGS: "get-agent-settings",
    DISPLAYED_GAMES: "displayed-games",
    FAVORITE_GAMES: "favorite-games",
  };

  export default moneyWinConfig;    