import React, { useState, useEffect } from "react";
import { games1 } from "../../../../static/constants";
import { useSelector } from "react-redux";

const GameCategory = ({ onMoreGamesClick, selectCategory, selectedCategory }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    setSelectedItem(selectedCategory);
    if(selectedCategory === null){
      setSelectedItem(null);
    }
  }, [selectedCategory]);

  const handleItemClick = (category) => {
    if (selectedItem === category) {
      setSelectedItem(null);
      selectCategory(null); 
    } else {
      setSelectedItem(category);
      selectCategory(category);

      if (onMoreGamesClick) {
        onMoreGamesClick(category);
        window.scrollTo(0, 0);
      }
    }
  };

  const filteredGames = token ? games1
  : games1.filter((item) => item.title !== "Favorites")

  return (
    <div
      className={`w-full flex overflow-x-scroll z-20 justify-start items-start xl:justify-center 2xl:items-center gap-2 p-5`}
    >
      {filteredGames.map((item, index) => (
        <div
          key={item.key}
          className={`bg-bgGameCategory px-4 py-2 xl:px-6 xl:py-3 flex gap-2 justify-center items-center text-white rounded-lg border-solid duration-300 cursor-pointer transform transition-transform ease-in-out ${
            selectedItem === item.title
              ? "border-2 border-yellow-500"
              : "border-2 border-gray-500"
          } hover:border-yellow-500 hover:scale-110 hover:z-10`}
          onClick={() => handleItemClick(item.title)}
        >
          <img alt="category" src={item.icon} className="w-[30px] h-auto" />
          <span className="text-[14px]">{item.title}</span>
        </div>
      ))}
    </div>
  );
};

export default GameCategory;
