import { Form, Input, Select, Table, Spin, Card, Button, Modal, message, Image } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useEffect ,useState} from "react";
import React from "react";
// import coinLogo from '../../../assets/resources/images/coin-logo.png';
import coinLogo from '../../../assets/resources/icons/mwIcons/mw-cash-small.webp';
import { getTransactionRecord, getUserInfo, getTopLevel, cashinRequest, modeOfPayment } from "../../../services/moneywin/moneywin-api";
import {cancelQrPayment, createQR, createWebPayment, webPaymentChannels, webPaymentStatus } from "../../../services/paycools/paycools-api";
import './userpanel.css';
import { formatDate, formatAsMoney } from "../../../services/util/utilities";
import { useSelector } from 'react-redux';

const Cashin = () => {
  const [form] = Form.useForm();
  const [cashinRecord, setCashinRecord] = useState([]);
  const [paymentChannel, setPaymentChannel] = useState(null);
  const [options, setOptions] = useState([]);
  const [accountName, setAccountName] = useState('');
  const [amount, setAmount] = useState('');
  const [customAmount, setCustomAmount] = useState('');
  const [reference, setReference] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [amountError, setAmountError] = useState('');
  const [nameError, setNameError] = useState('');
  const [referenceError, setReferenceError] = useState('');
  const [paymentChannelError, setPaymentChannelError] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [customAmountError, setCustomAmountError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [gatewayCashinEnabled, setGatewayCashinEnable] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [customAmountVisible, setCustomAmountVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");
  const [manual, setManual] = useState(false)
  const [pendingQr, setPendingQr] = useState('');
  const [isPaymentLoading, setIsPaymentLoading] = useState(true);
  const token = useSelector((state) => state.auth.token);
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
  
    const fetchData = async () => {
      // setIsPaymentLoading(true);
      const response = await getUserInfo(token);
      if (response && response.user_id) {
        try {
          const requests = await getTransactionRecord(response.user_id, "cashin");
          if (requests && requests.length > 0) {
            setCashinRecord(requests);
            checkPendingTransactions(requests);
          }
        } catch (error) {
          console.error("Error fetching cash-in records:", error);
        }
      }else if(response.timed_out || response.request_failed){
        message.error({ content: "Unable to retrieve cashin history. Please check your internet connection or try again later." , key: 'custom_message' });
      }

      setTableLoading(false);
    };

    const checkPendingQr = async (requests) => {
      if(qrModalVisible){
        if (requests && requests.length > 0) {
          const lastRecord = requests[requests.length - 1];
          if (lastRecord.transaction_reference === pendingQr && lastRecord.status === 'completed') {
            handleQrModalClose();
          }
        }
      }
    };

    
    const checkPendingTransactions = async (requests) => {
      if (requests && requests.length > 0) {
        const lastRecord = requests[0];
        if (lastRecord.status === "pending payment" || lastRecord.status === "pending") {
          await webPaymentStatus(lastRecord.transaction_reference, lastRecord.status);
        }
      }
    };
    

    const refreshTable = async () => {
      const userInfo = await getUserInfo(token);
      if (userInfo && userInfo.user_id) {
        try {
          const requests = await getTransactionRecord(userInfo.user_id, "cashin");
          if (requests && requests.length > 0) {
            setCashinRecord(requests);
            checkPendingQr(requests);
            //checkPendingTransactions(requests);
          }
        } catch (error) {
          console.error("Error fetching cash-in records:", error);
        }
      }
    }
  
    fetchData();

    const interval = setInterval(refreshTable, 10000);
    
    return () => {
      clearInterval(interval);
    };

  }, [token, pendingQr, qrModalVisible]);


  useEffect(() => {
    const fetchTopLevelPaymentChannels = async () => {
      try {
        if (currentUser) {
          const response = await modeOfPayment('get_active', currentUser.userId);
            if (response && response.success) {

              setGatewayCashinEnable(response.automated_cashin)

              const formattedOptions = response.mode_of_payments.map((option) => ({
                value: option.payment_channel + " MANUAL (" + option.account_number + ")",
                label: option.payment_channel + " (" + option.account_number + ")",
              }));

              // Set your state or perform other logic based on this result
              setOptions((prevOptions) => [...prevOptions, ...formattedOptions]);
            }else{
              message.error({ content: "Unable to retrieve payment channels. Please try again later." , key: 'custom_message' });
            }
        }
      } catch (error) {
        console.error("Error fetching top-level options:", error);
      }
    };

    fetchTopLevelPaymentChannels();
  }, [currentUser]);

  // For other users
  useEffect(() => {
    const fetchOtherPaymentChannels = async () => {
      try {
        if (currentUser) {
          if (gatewayCashinEnabled) {
            const response = await webPaymentChannels();
            if (response.code === 1000 && response.data) {
              const formattedOptions = response.data.map((option) => ({
                value: option.channelCode,
                label: option.channelName.split('-')[0].trim(),
              }));

              formattedOptions.push({
                value: "qr_ph",
                label: "Pay via QR",
              });

              setOptions((prevOptions) => [...prevOptions, ...formattedOptions]);
            }else{
              message.error({ content: "Unable to retrieve payment channels. Please try again later." , key: 'custom_message' });
            }
          }
        }
        setIsPaymentLoading(false);
      } catch (error) {
        console.error("Error fetching other options:", error);
      }
    };

    fetchOtherPaymentChannels();
    
  }, [currentUser, gatewayCashinEnabled]);
  
  const handleSumbitClick = async () => {
    if (!amount && !accountName && !accountNumber && !reference && !paymentChannel){
      return;
    }
    setIsLoading(true);
    const userInfo = await getUserInfo(token);
    if (userInfo.user_id) {

      if (!paymentChannel) {
        setPaymentChannelError('Please select a payment channel!');
        setIsLoading(false);
        return;
      } else {
        setPaymentChannelError('');
      }

      if(customAmountVisible){
        if (!customAmount) {
          setCustomAmountError('Please select an amount!');
          setIsLoading(false);
          return;
        } else if (isNaN(customAmount)) {
          setCustomAmountError('Invalid Amount!');
          setIsLoading(false);
          return;
        } else if (customAmount > 20000 || customAmount < 100) {
          setCustomAmountError('Invalid Amount!');
          setIsLoading(false);
          return;
        } else {
          setCustomAmountError('');
        }
      }else{
        if (!amount) {
          setAmountError('Please select an amount!');
          setIsLoading(false);
          return;
        } else if (isNaN(amount)) {
          setAmountError('Invalid Amount!');
          setIsLoading(false);
          return;
        } else {
          setAmountError('');
        }
  
      }
  
      // Validate payment channel, account name, amount, and account number
      if (manual && !reference) {
        if(!reference){
          setReferenceError('Please enter transaction reference!');
          setIsLoading(false);
          return;
        }else {
          setReferenceError('');
        }
        
      } 
  
      if (manual && !accountName) {
        setNameError('Please enter account name!');
        setIsLoading(false);
        return;
      } else {
        setNameError('');
      }
  
      
      if (manual && !accountNumber) {
        setAccountNumberError('Please enter account number!');
        setIsLoading(false);
        return;
      } else {
        setAccountNumberError('');
      }

      if(manual){
        try{
          const cashin = await cashinRequest(
            userInfo.user_id,
            paymentChannel,
            reference,
            accountName,
            accountNumber,
            customAmountVisible ? customAmount : amount
          );

          if(cashin.success){
            message.success({ content: "Cash-in requested successfully!", key: 'custom_message' });
            handleClearClick();
          }else{
            message.error({ content: "Cash-in request failed: " + cashin.message , key: 'custom_message' });
          }
        } catch (error) {
          // Handle any errors that occurred during API call
          console.error("Error requesting manual cash-in:", error);
        }
      }else{
        const topLevel = await getTopLevel(userInfo.user_id);

        if(topLevel && topLevel.user_id){
          try {
            if(paymentChannel === "qr_ph"){
              const qrCode = await createQR(userInfo.user_id, topLevel.user_id, "BWM", amount)
              if (qrCode.message === "success") {
                setPaymentLink(qrCode.data.qrLink);
                setPendingQr(qrCode.data.mchOrderId);
                setQrModalVisible(true);
                handleClearClick();
              }else{
                message.error({ content: "Cash-in request failed. Please try again later." , key: 'custom_message' });
              }

            }else{
              const webPayment = await createWebPayment(
                userInfo.user_id,
                topLevel.user_id,
                amount,
                userInfo.email,
                paymentChannel
              );

              if (webPayment.message === "success") {
                setPaymentLink(webPayment.data.redirectUrl);
                window.open(webPayment.data.redirectUrl);
                // console.log(webPayment);
                handleClearClick();
                setModalVisible(true);
              } else {
                // console.log(webPayment.message);
                if (webPayment.message.includes("mobile")) {
                  const errorMessage = webPayment.message.split(':')[1].trim(); // Extract error message
                  setAccountNumberError(errorMessage);
                }else{
                  message.error({ content: "Cash-in request failed. Please try again later." , key: 'custom_message' });
                }
              }
            }
          } catch (error) {
            setIsLoading(false);
          }
        }
      }
    }
      
    setIsLoading(false);
    
  };

  const handleCancelQrPayment = async () => {
    const cancel = await cancelQrPayment(pendingQr);
    if(cancel.message === "success"){
      handleQrModalClose();
    }
  }

  const handleAmountSelection = (selectedAmount) => {
    if(selectedAmount === "custom"){
      setAmount("custom");
      setCustomAmountVisible(true);
    }else{
      setCustomAmountVisible(false);
      setAmount(selectedAmount);
      setAmountError(null);
    }
  };

  const handlePaymentChannelChange = (selectedOption) => {
    if (selectedOption && selectedOption.includes("MANUAL")) {
      setManual(true);
    }else{
      setManual(false);
    }
    setPaymentChannel(selectedOption);
  }



  const handleModalClose = () => {
    // Close the modal and clear any error messages
    setModalVisible(false);
    setPendingQr('');
    setAccountNumberError("");
    // Add other error states here if needed
  };

  const handleQrModalClose = () => {
    // Close the modal and clear any error messages
    setQrModalVisible(false);
    setPaymentLink("");
    // Add other error states here if needed
  };

  const handleClearClick = () => {
    setAccountName('');
    setAccountNumber('');
    setAmount('');
    setReference('');
    setPaymentChannel(null);
    setCustomAmount('');
    setCustomAmountVisible(false);
    form.resetFields(['custom-amount']);
    form.resetFields(['name']);
    form.resetFields(['amount']);
    form.resetFields(['mobile']);
    form.resetFields(['transaction-reference']);
    form.resetFields(['payment-channel']);
  }
  const uniqueNames = [...new Set(cashinRecord.map(item => item.status))];
  const filters = uniqueNames.map(status => ({
    text: status.charAt(0).toUpperCase() + status.slice(1),
    value: status,
  }));

  const columns = [
    {
      title: "Date Requested",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      // filters: [
      //   {
      //     text: 'YYYY-MM-DD HH:mm:ss',
      //     value: 'dateandtime',
      //   },
      // ],
      // render: (text) => {
      //   return <span>{formatDate(text)}</span>
      // },
      // render: (text, record) => {
      //   const isFilterApplied = record.filters && record.filters.length > 0;
      //   if (isFilterApplied) {
      //     return <span>{record.created_at}</span>;
      //   } else {
      //     return <span>{formatDate(text)}</span>;
      //   }
      // },
      // onFilter: (value, record) => {
      //   if (value === 'dateandtime') {
      //     // return true; // Filter condition if 'dateandtime' is selected
      //     setChangeDateFormat(true);
      //     console.log('etrue',true);
      //     console.log(record.created_at);
      //   }
      //   // return false; // No filter applied or other values
      // },
      render: (text) => {
        // return <span>{!changeDateFormat ? text : formatDate(text)}</span>;
        return <span>{formatDate(text)}</span>
      },
    },
    {
      title: "Reference Number",
      dataIndex: "transaction_reference",
      key: "transaction_reference",
      align: "center",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (amount, record) => {
        return (
          <span>
            {formatAsMoney(amount-record.service_charge)}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (type, record) => {
        let statusColor;
        if(record.status === "pending"){
          statusColor = 'text-yellow-300';
        }
        if(record.status === "completed"){
          statusColor = 'text-green-500';
        }
        if(record.status === "failed"){
          statusColor = 'text-red-500';
        }
        if(record.status === "cancelled"){
          statusColor = 'text-gray-400';
        }
        if(record.status === "declined"){
          statusColor = 'text-gray-400';
        }
        return (
          <span className={`${statusColor} font-semibold`}>{record.status.charAt(0).toUpperCase() + type.slice(1)}</span>
        )
      },
      filters,
      // filter: [
      //   {
      //     text: status,
      //     value: status,
      //   }
      // ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      align: "center",
      render: (remarks) => (remarks !== null ? remarks : "-"),
    },
    
    
  ];

  const onFinish = () => {};
  const onFinishFailed = () => {};


  return (
    
    <div className="flex flex-col w-full h-full bg-primary py-12 md:px-10 px-3 gap-10">
      {/* <div className="flex justify-between md:items-center gap-5 w-full flex-col md:flex-row"> */}
      {/* <div className="flex justify-center md:items-center gap-5 w-full flex-col md:flex-row">
        <span className="text-textYellow text-2xl">Cash-In</span>
      </div> */}
      <Modal
        open={qrModalVisible}
        onClose={handleQrModalClose}
        onCancel={handleCancelQrPayment}
        onOk={handleQrModalClose}
        title="Payment"
      >
        <iframe
          className="w-full h-full"
          title="Payment"
          src={paymentLink}
          style={{ width: '100%', height: '500px' }} // Adjust dimensions as needed
        />
      </Modal>
      <Modal
        title="Payment"
        open={modalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {/* Display the payment link here */}
        <p>
          If you are not automatically redirected,{' '}
          <a href={paymentLink} target="_blank" rel="noopener noreferrer">
            click here
          </a>{' '}
          to proceed with the payment.
        </p>
        {/* You can customize the content of the modal as needed */}
      </Modal>
      {/* { isPaymentLoading ? (
        <div className="text-white flex justify-center items-center mt-[200px]">
          <Spin size="large"/>
        </div>
      ) : (
        
      )} */}
      <div className="w-full h-full flex flex-col gap-0 border-none items-center justify-center xl:px-7 xl:py-0 md:border-gray-800">
        <div className="w-full absolute left-0 top-0 right-0 bg-[#1D2230] h-[300px] z-[0]"></div>
        <div className="flex flex-col gap-3 justify-center items-center mb-10 z-[5]">
          <span className="text-white text-2xl font-medium">Cash-In</span>
          <span className="text-white text-center">Recharge your account and start winning now! <br/> Use any of the following payment channels listed below.</span>
        </div>
        <div className={`flex flex-col w-full justify-center items-center ${isPaymentLoading && 'h-[560px]'} max-w-[800px] gap-5 p-5 md:p-5 xl:p-10 rounded-lg mx-auto bg-gray-800 z-[5]`}>
          
          {isPaymentLoading ? (
            <Spin />
          ) : (
            <>
              <Form
                layout="vertical"
                className="w-full"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                {/* <div className="grid lg:grid-cols-2 items-start gap-5 w-full md:p-10"> */}
                <div className="flex justify-center flex-wrap lg:flex-nowrap mx-auto items-start gap-5 w-full md:p-10">
                  <div className="flex flex-col w-full xl:grid xl:grid-cols-2 gap-2">
                    <div className={`flex flex-col ${!manual ? 'col-span-2' : 'col-span-1'}`}>
                      <Form.Item
                        name="payment-channel"
                        label={
                          <span className="text-white">
                            Payment Channel
                          </span> 
                        }
                        value={paymentChannel}
                        validateStatus={paymentChannelError ? 'error' : ''}
                        help={paymentChannelError}
                      >
                        <Select
                          size="large"
                          defaultValue="Select Payment Channel"
                          onChange={(selectedOption) => {
                            handlePaymentChannelChange(selectedOption);
                          }}
                          options={options}
                          loading={isPaymentLoading}
                        >
                          {isPaymentLoading && <Spin/>}
                        </Select>
                      </Form.Item>
                      {manual && (
                        <Form.Item
                          name="transaction-reference"
                          label={<span className="text-white">Transaction Reference</span>}
                          validateStatus={referenceError ? "error" : ""}
                          help={referenceError}
                        >
                          <Input
                            size="large"
                            style={{
                              width: "100%",
                            }}
                            value={reference}
                            onChange={(e) => {
                              setReference(e.target.value);
                              setReferenceError("");
                            }}
                          />
                        </Form.Item>
                      )}
                    </div>
                    
                    {manual && (
                      <div className="flex flex-col w-full order-last xl:order-none">
                        <Form.Item
                          name="name"
                          label={<span className="text-white">Name</span>}
                          validateStatus={nameError ? 'error' : ''}
                          help={nameError}
                          rules={[
                            {
                              pattern: new RegExp(
                                /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-]+$/i
                              ),
                              message: "Please input your name!",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            style={{
                              width: "100%",
                            }}
                            type="text"
                            // onChange={(e) => {
                            //   setAccountName(e.target.value)
                            //   setNameError("");
                            // }}
                            onChange={(e) => {
                              setAccountName(e.target.value);
                              setNameError('');
                            }}
                            value={accountName}
                          />
                        </Form.Item>
                        <Form.Item
                          name="mobile"
                          label={<span className="text-white">Account Number</span>}
                          validateStatus={accountNumberError ? 'error' : ''}
                          help={accountNumberError}
                        >
                          <Input
                            size="large"
                            type="number"
                            min={1}
                            style={{
                              width: "100%",
                              
                            }}
                            onChange={(e) => {
                              setAccountNumber(e.target.value)
                              setAccountNumberError("");
                            }}
                            value={accountNumber}
                          />
                        </Form.Item>
                      </div>
                    )}
                    <Form.Item
                      className="flex-grow w-full xl:col-span-2"
                      name="amount"
                      label={<span className="text-white">Amount</span>}
                      validateStatus={amountError ? 'error' : ''}
                      help={amountError}
                    >
                      <Button.Group style={{ width: '100%' }}>
                        <Button
                          type={amount === '100' ? 'primary' : 'default'}
                          onClick={() => handleAmountSelection('100')}
                          size="large"
                          style={{
                            flex: 1,
                          }}
                        >
                          <div className="flex justify-center items-center gap-[2px] md:gap-1">
                            <Image src={coinLogo} preview={false} style={{width:17,height:17,}} className="mt-[-4px]"/>
                            <span className="text-[11px] md:text-[16px]">100</span>
                          </div>
                        </Button>
                        <Button
                          type={amount === '500' ? 'primary' : 'default'}
                          onClick={() => handleAmountSelection('500')}
                          size="large"
                          style={{
                            flex: 1,
                          }}
                        >
                          <div className="flex justify-center items-center gap-[2px] md:gap-1">
                            <Image src={coinLogo} preview={false} style={{width:17,height:17,}} className="mt-[-4px]"/>
                            <span className="text-[11px] md:text-[16px]">500</span>
                          </div>
                        </Button>
                        <Button
                          type={amount === '1000' ? 'primary' : 'default'}
                          onClick={() => handleAmountSelection('1000')}
                          size="large"
                          style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <div className="flex justify-center items-center gap-[2px] md:gap-1">
                            <Image src={coinLogo} preview={false} style={{width:17,height:17,}} className="mt-[-4px]"/>
                            <span className="text-[11px] md:text-[16px]">1,000</span>
                          </div>
                        </Button>
                        <Button
                          type={amount === '5000' ? 'primary' : 'default'}
                          onClick={() => handleAmountSelection('5000')}
                          size="large"
                          style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <div className="flex justify-center items-center gap-[2px] md:gap-1">
                            <Image src={coinLogo} preview={false} style={{width:17,height:17,}} className="mt-[-4px]"/>
                            <span className="text-[11px] md:text-[16px]">5,000</span>
                          </div>
                        </Button>
                      </Button.Group>
                      <Button.Group style={{ width: '100%' }} className="mt-2">
                        <Button
                          type={amount === '10000' ? 'primary' : 'default'}
                          onClick={() => handleAmountSelection('10000')}
                          size="large"
                          style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <div className="flex justify-center items-center gap-[2px] md:gap-1">
                            <Image src={coinLogo} preview={false} style={{width:17,height:17,}} className="mt-[-4px]"/>
                            <span className="">10,000</span>
                          </div>
                        </Button>
                        <Button
                          type={amount === '20000' ? 'primary' : 'default'}
                          onClick={() => handleAmountSelection('20000')}
                          size="large"
                          style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <div className="flex justify-center items-center gap-[2px] md:gap-1">
                            <Image src={coinLogo} preview={false} style={{width:17,height:17,}} className="mt-[-4px]"/>
                            <span className="">20,000</span>
                          </div>
                        </Button>
                      </Button.Group>
                      <Button.Group style={{ width: '100%' }} className="mt-2">
                        <Button
                          type={amount === 'custom' ? 'primary' : 'default'}
                          onClick={() => handleAmountSelection('custom')}
                          size="large"
                          style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <div className="flex justify-center items-center gap-[2px] md:gap-1">
                            <span className="">Custom Amount</span>
                          </div>
                        </Button>
                      </Button.Group>
                      {customAmountVisible && (
                        <Form.Item
                          className="mt-2"
                          name="custom-amount"
                          label={<span className="text-white">Enter Custom Amount</span>}
                          validateStatus={customAmountError ? 'error' : ''}
                          help={customAmountError}
                        >
                          <Input
                            size="large"
                            type="number"
                            min={1}
                            style={{
                              width: "100%",
                            }}
                            onChange={(e) => {
                              setCustomAmount(e.target.value)
                              setCustomAmountError("");
                            }}
                            value={customAmount}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </div>
                </div>
              </Form>
              <div className="flex flex-col md:flex-row w-full md:px-10 mx-auto gap-5 items-center ">
                <button
                  type="primary"
                  htmltype="submit"
                  style={{
                    border: "1px solid red",
                  }}
                  onClick={handleClearClick}
                  className="w-full cursor-pointer text-[18px] h-[55px] font-semibold bg-transparent bg-red-600 hover:bg-red-400 text-white border-0 rounded-lg duration-150"
                >
                  Clear
                </button>
                <button
                  type="primary"
                  htmltype="submit"
                  disabled={isLoading}
                  onClick={handleSumbitClick}
                  className="w-full cursor-pointer text-[18px] h-[55px] font-semibold bg-[#0274BE] hover:bg-blue-400 text-white border-0 rounded-lg duration-150"
                >
                  {isLoading ? (
                    <Spin
                      indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fff', strokeWidth: 2 }} spin />}
                    />
                    ) : (
                      "Submit"
                  )}
                </button>
              </div>
            </>
          )}
        </div>
        <div className="md:pr-10 items-start gap-5 w-full md:p-10 pb-10 pt-2 min-h-[300px]">
          <Card> 
            <Table 
              className="min-h-[300px]"
              // bordered 
              loading={tableLoading}
              dataSource={cashinRecord} 
              columns={columns} 
              rowKey={"key"}
              scroll={{
                x: 1000,
                y: '100%',
              }}
            />
          </Card>
        </div>
        </div>
    </div>
  );
};

export default Cashin;