import React from "react";
// import star from "../../../../assets/resources/icons/star.png";
import star from "../../../../assets/resources/icons/mwIcons/MONEYWIN ICONS-ALL-GAMES.png";
import { Image, Skeleton, Spin, message, Modal } from "antd";
import { Loading3QuartersOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useState, useRef } from "react";
import { getJiliGames } from "../../../../services/jili/jili-api";
import { useNavigate } from "react-router-dom";
import favoriteIcon from "../../../../assets/resources/icons/mwIcons/MONEYWIN ICONS-HEART.png";
import favoriteIconFill from "../../../../assets/resources/icons/mwIcons/MONEYWIN ICONS-HEART-FILL.png";
import {
  getUserInfo,
  displayedGames as gamesToHide,
  favoriteGames,
} from "../../../../services/moneywin/moneywin-api";
import {
  getGameIcon,
  showTimedMessageWithAction,
} from "../../../../services/util/utilities";
// import defaultIconUrl from '../../../../assets/resources/icons/default.png';
import defaultIconUrl from "../../../../assets/resources/logos/icon/MONEYWIN LOGO-01.png";
import { spGetGames } from "../../../../services/sp/sp-api";
import { saGetGames } from "../../../../services/sa/sa-api";
import PopularGames from "./PopularGames";
import GamingProvider from "./GamingProvider";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../../store/slices/auth-slice";

import "./games.css";
import GameCategory from "./GameCategory";

const AllGames = ({
  selectCategory,
  categoriesToShow,
  toggleRegModal,
  selectedProvider,
  providerToShow,
  navigateToSignUp,
  onMoreGamesClick, // Accept the callback as a prop
}) => {
  const [favorites, setFavorites] = useState([]);
  const [hiddenProviders, setHiddenProviders] = useState([]);
  const [categorizedGames, setCategorizedGames] = useState({});
  const [loadingGames, setLoadingGames] = useState({});
  const [loadedIcons, setLoadedIcons] = useState({});
  const [displayedGames, setDisplayedGames] = useState({});
  const [loadedCategories, setLoadedCategories] = useState([]);
  const [gamesLoaded, setGamesLoaded] = useState(false);
  const [loadingMoreGames, setLoadingMoreGames] = useState({});
  const [search, setSearch] = useState("");
  const [filteredGames, setFilteredGames] = useState(null);
  const loadedIconsRef = useRef(loadedIcons);
  const [gameLoading, setGameLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const iPhoneSEBreakPoint = 375;
  const isIphoneSE = window.innerWidth <= iPhoneSEBreakPoint;
  const navigate = useNavigate();
  const [messageBox, messageHolder] = Modal.useModal();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    setTimeout(() => {
      setGamesLoaded(true);
    }, 20);
  }, []);

  useEffect(() => {
    const fetchProvidersToHide = async () => {
      if (currentUser) {
        const response = await gamesToHide("get-top", currentUser.userId);
        if (response.not_displayed_providers) {
          const providersToHide = Object.keys(response.not_displayed_providers);
          setHiddenProviders(providersToHide);
        }
      }
    };

    fetchProvidersToHide();
  }, [currentUser]);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        let games = {};

        let cachedData = localStorage.getItem("gameData");

        if (cachedData) {
          const parsedData = JSON.parse(cachedData);
          const { timestamp, data } = parsedData;

          const now = new Date().getTime();
          const oneHour = 60 * 60 * 1000;

          if (!timestamp || now - timestamp > oneHour) {
            cachedData = null;
          } else {
            games = data;
          }
        }

        if (!cachedData) {
          let jiliGames, spGames, saGames;

          if (!hiddenProviders.includes("jili")) {
            jiliGames = await getJiliGames("EN", true);
          }

          if (!hiddenProviders.includes("simpleplay")) {
            spGames = await spGetGames();
          }

          if (!hiddenProviders.includes("sa")) {
            saGames = await saGetGames();
          }

          const providers = [jiliGames, spGames, saGames].filter(Boolean);

          // const [jiliGames, spGames] = await Promise.all([
          //   getJiliGames("EN", true),
          //   spGetGames(),
          // ]);

          // const providers = [jiliGames, spGames];

          for (const providerData of providers) {
            for (const [category, gamesInCategory] of Object.entries(
              providerData
            )) {
              if (!games[category]) {
                games[category] = [];
              }
              games[category].push(...gamesInCategory);
            }
          }

          for (const category in games) {
            games[category].sort((gameA, gameB) => {
              const nameA = gameA.name["en-US"];
              const nameB = gameB.name["en-US"];
              return nameA.localeCompare(nameB);
            });
          }

          const updatedData = { timestamp: new Date().getTime(), data: games };
          localStorage.setItem("gameData", JSON.stringify(updatedData));
        }

        if (providerToShow) {
          for (const category in games) {
            games[category] = games[category].filter(
              (game) => game.provider === providerToShow
            );
            // Check if the category is empty (has no games) and remove it
            if (games[category].length === 0) {
              delete games[category];
            }
          }
        }

        // Filter and set the combined games into state
        if (categoriesToShow) {
          if(categoriesToShow === "Favorites"){
            const favoriteGamesList = {};
            for (const category in games) {
                const filteredGames = games[category].filter((game) =>
                  favorites.includes(game.GameId.toString())
                );
                if (filteredGames.length > 0) {
                    favoriteGamesList[category] = filteredGames;
                }
            }
            games = favoriteGamesList;
          }else{
            games = Object.fromEntries(
              Object.entries(games).filter(([category]) =>
                categoriesToShow.includes(category)
              )
            );
          }
        }

        setCategorizedGames(games);
      } catch (error) {
        console.error("Error fetching games:", error);
      }
    };

    fetchGames();
  }, [categoriesToShow, providerToShow, hiddenProviders, favorites]);

  useEffect(() => {
    loadedIconsRef.current = loadedIcons;
  }, [loadedIcons]);

  useEffect(() => {
    const loadInitialGames = () => {
      const initialDisplayedGames = {};
      Object.entries(categorizedGames).forEach(
        ([category, gamesInCategory]) => {
          initialDisplayedGames[category] = isMobile ? 6 : 5;
          gamesInCategory.slice(0, isMobile ? 6 : 5).forEach((game) => {
            const gameId = game.GameId;
            const provider = game.provider ? game.provider : null;
            if (!loadedIconsRef.current[gameId]) {
              loadGameIcon(gameId, provider);
            }
          });
        }
      );
      setDisplayedGames(initialDisplayedGames);
    };
    // const loadInitialGames = () => {
    //   const initialDisplayedGames = {};
    //   Object.entries(categorizedGames).forEach(
    //     ([category, gamesInCategory]) => {
    //       const totalGames = gamesInCategory.length;
    //       initialDisplayedGames[category] = totalGames; // Display all games

    //       gamesInCategory.slice(0, totalGames).forEach((game) => {
    //         const gameId = game.GameId;
    //         const provider = game.provider ? game.provider : null;
    //         if (!loadedIconsRef.current[gameId]) {
    //           loadGameIcon(gameId, provider);
    //         }
    //       });
    //     }
    //   );
    //   setDisplayedGames(initialDisplayedGames);
    // };

    const loadGameIcon = async (gameId, provider) => {
      if (!loadedIconsRef.current[gameId]) {
        try {
          let iconUrl;

          if (provider === "SA") {
            iconUrl = await getGameIcon(gameId, "sa");
          } else if (provider === "SimplePlay") {
            iconUrl = await getGameIcon(gameId, "sp");
          } else {
            iconUrl = await getGameIcon(gameId, "jili");
          }
          setLoadedIcons((prevLoadedIcons) => {
            return {
              ...prevLoadedIcons,
              [gameId]: iconUrl,
            };
          });
        } catch (error) {
          setLoadedIcons((prevLoadedIcons) => ({
            ...prevLoadedIcons,
            [gameId]: defaultIconUrl,
          }));
        }
      }
    };

    loadInitialGames();
  }, [categorizedGames]);

  const renderGameIcon = (gameId) => {
    const iconUrl = loadedIcons[gameId];

    if (loadingGames[gameId]) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin
            size="large"
            indicator={
              <Loading3QuartersOutlined style={{ fontSize: 24 }} spin />
            }
          />
        </div>
      );
    } else if (iconUrl) {
      return (
        <Image
          alt={gameId}
          preview={false}
          src={iconUrl}
          width={isMobile ? 105 : window.innerWidth >= 768 && 250} //Old Width
          height={isMobile ? 105 : window.innerWidth >= 768 && 250} //Old Width
          // width={
          //   isMobile
          //     ? isIphoneSE
          //       ? 155
          //       : 175
          //     : window.innerWidth >= 768 && 250
          // } //New Width
          // height={
          //   isMobile
          //     ? isIphoneSE
          //       ? 155
          //       : 175
          //     : window.innerWidth >= 768 && 250
          // } //New Width
          style={{ width: "100%" }}
          loading="lazy"
        />
      );
    } else {
      return (
        <div>
          <Skeleton.Image
            active
            className="w-[150px] md:w-[250px] bg-secondary rounded-md md:min-w-[250px] sm:h-auto min-h-[100px] md:min-h-[250.4px]"
          />
        </div>
      );
    }
  };

  const handleGameClick = async (gameId, gameName, provider) => {
    if (!gameLoading) {
      setLoadingGames((prevLoadingGames) => ({
        ...prevLoadingGames,
        [gameId]: true,
      }));
      setGameLoading(true);

      try {
        const response = await getUserInfo(token);
        if (response.role_id && response.username) {
          if (response.role_id === 5) {
            if (gameName === "Evolution Gaming") {
              navigate("/game", {
                state: {
                  gameId,
                  username: response.username,
                  gameProvider: "EVO",
                },
              });
            } else if (provider && provider === "SimplePlay") {
              navigate("/game", {
                state: {
                  gameId,
                  gameName,
                  username: response.username,
                  gameProvider: "SIMPLE_PLAY",
                },
              });
            } else if (provider && provider === "SA") {
              navigate("/game", {
                state: {
                  gameId,
                  gameName,
                  username: response.username,
                  gameProvider: "SA",
                },
              });
            } else {
              navigate("/game", {
                state: {
                  gameId,
                  gameName,
                  username: response.username,
                  gameProvider: "JILI",
                },
              });
            }
            window.scrollTo(0, 0); // Scroll to the top after the redirect
          } else {
            window.location.reload();
          }
        } else if (response.timed_out || response.request_failed) {
          message.error({
            content:
              "Unable to start game. Please check your internet connection or try again later.",
            key: "custom_message",
          });
        } else if (response.error) {
          if (token) {
            showTimedMessageWithAction(
              messageBox,
              "Session Expired",
              "Your session has expired. To continue, please log in again.",
              "Login",
              3,
              true,
              "warning",
              async () => {
                dispatch(logout());
              }
            );
          } else {
            if (window.innerWidth <= 768) {
              navigateToSignUp(); // Navigate to /signup on mobile
            } else {
              toggleRegModal(true); // Toggle registration modal on non-mobile
            }
          }
        } else {
          message.error({
            content:
              "Unable to start game. Please check your internet connection or try again later.",
            key: "custom_message",
          });
        }
      } catch (error) {
        console.error(error);
      }

      setLoadingGames((prevLoadingGames) => ({
        ...prevLoadingGames,
        [gameId]: false,
      }));
      setGameLoading(false);
    }
  };

  //New - xlx
  // const [displayedGames, setDisplayedGames] = useState(5);

  // const handleMoreGamesClick = async (category) => {
  //   if (loadingMoreGames[category]) {
  //     return; // Do nothing if already loading more games for this category
  //   }

  //   setLoadingMoreGames((prevLoadingMoreGames) => ({
  //     ...prevLoadingMoreGames,
  //     [category]: true, // Set loading state to true for the clicked category
  //   }));

  //   const newDisplayedGames = (displayedGames[category] || 0) + 5;

  //   setDisplayedGames((prevDisplayedGames) => ({
  //     ...prevDisplayedGames,
  //     [category]: newDisplayedGames,
  //   }));

  //   if (!loadedCategories.includes(category)) {
  //     setLoadedCategories((prevLoadedCategories) => [
  //       ...prevLoadedCategories,
  //       category,
  //     ]);
  //   }

  //   // Load game icons for the newly displayed games in the clicked category
  //   const gamesInCategory = categorizedGames[category];
  //   const gamesToLoad = gamesInCategory.slice(
  //     displayedGames[category] || 0,
  //     newDisplayedGames
  //   );
  //   const newPromises = gamesToLoad.map(async (game) => {
  //     const gameId = game.GameId;
  //     const provider = game.provider ? game.provider : null;

  //     if (!loadedIcons[gameId]) {
  //       try {
  //         let iconUrl;

  //         if (provider === "SA") {
  //           iconUrl = await getGameIcon(gameId, "sa");
  //         } else if (provider === "SimplePlay") {
  //           iconUrl = await getGameIcon(gameId, "sp");
  //         } else {
  //           iconUrl = await getGameIcon(gameId, "jili");
  //         }
  //         setLoadedIcons((prevLoadedIcons) => ({
  //           ...prevLoadedIcons,
  //           [gameId]: iconUrl,
  //         }));
  //       } catch (error) {
  //         console.error("Error fetching game icon:", error);
  //         setLoadedIcons((prevLoadedIcons) => ({
  //           ...prevLoadedIcons,
  //           [gameId]: defaultIconUrl, // Use default icon URL in case of an error
  //         }));
  //       }
  //     }
  //   });

  //   try {
  //     await Promise.all(newPromises); // Wait for the new promises to resolve
  //   } catch (error) {
  //     console.error("Error loading game icons:", error);
  //   } finally {
  //     setLoadingMoreGames((prevLoadingMoreGames) => ({
  //       ...prevLoadingMoreGames,
  //       [category]: false, // Set loading state to false after the data is fetched
  //     }));
  //   }
  // };

  const handleMoreGamesClick = async (category) => {
    if (loadingMoreGames[category]) {
      return; // Do nothing if already loading more games for this category
    }

    setLoadingMoreGames((prevLoadingMoreGames) => ({
      ...prevLoadingMoreGames,
      [category]: true, // Set loading state to true for the clicked category
    }));

    // Set the number of displayed games to the total number of games in the category
    const totalGamesInCategory = categorizedGames[category]?.length || 0;

    setDisplayedGames((prevDisplayedGames) => ({
      ...prevDisplayedGames,
      [category]: totalGamesInCategory,
    }));

    if (!loadedCategories.includes(category)) {
      setLoadedCategories((prevLoadedCategories) => [
        ...prevLoadedCategories,
        category,
      ]);
    }

    // Load game icons for all the games in the clicked category
    const gamesInCategory = categorizedGames[category];
    const gamesToLoad = gamesInCategory.slice(0, totalGamesInCategory);
    const newPromises = gamesToLoad.map(async (game) => {
      const gameId = game.GameId;
      const provider = game.provider ? game.provider : null;

      if (!loadedIcons[gameId]) {
        try {
          let iconUrl;

          if (provider === "SA") {
            iconUrl = await getGameIcon(gameId, "sa");
          } else if (provider === "SimplePlay") {
            iconUrl = await getGameIcon(gameId, "sp");
          } else {
            iconUrl = await getGameIcon(gameId, "jili");
          }
          setLoadedIcons((prevLoadedIcons) => ({
            ...prevLoadedIcons,
            [gameId]: iconUrl,
          }));
        } catch (error) {
          console.error("Error fetching game icon:", error);
          setLoadedIcons((prevLoadedIcons) => ({
            ...prevLoadedIcons,
            [gameId]: defaultIconUrl, // Use default icon URL in case of an error
          }));
        }
      }
    });

    try {
      await Promise.all(newPromises); // Wait for the new promises to resolve
    } catch (error) {
      console.error("Error loading game icons:", error);
    } finally {
      setLoadingMoreGames((prevLoadingMoreGames) => ({
        ...prevLoadingMoreGames,
        [category]: false, // Set loading state to false after the data is fetched
      }));
    }

    if (onMoreGamesClick) {
      onMoreGamesClick(category);
      window.scrollTo(0, 0);
    }
  };
  // Favorite Icon Start
  const [isAddingFavorite, setIsAddingFavorite] = useState(false);
  
  useEffect(() => {
    const fetchFavoriteGames = async () => {
      if (currentUser && currentUser.userId) {
        setIsAddingFavorite(true); 
    
        try {
          const fetchedFavorites = await favoriteGames("get", currentUser.userId);
          setFavorites(fetchedFavorites.favorites || []);
        } catch (error) {
          console.log("Something went wrong fetching favorite games.");
        }
    
        setIsAddingFavorite(false);
      }
    };

    fetchFavoriteGames();
  }, [currentUser]);

  const handleFavoriteClick = async (gameId, gameName) => {
    if (currentUser && currentUser.userId) {
      if (!isAddingFavorite) {
        setIsAddingFavorite(true);

        const isFavorite = favorites.includes(gameId.toString());
        const action = isFavorite ? "remove" : "add";
        const success = await favoriteGames(action, currentUser.userId, gameId);
  
        if (success) {
          setFavorites((prevFavorites) => {
            const favoriteSet = new Set(prevFavorites);
  
            if (favoriteSet.has(gameId.toString())) {
              favoriteSet.delete(gameId.toString());
            } else {
              favoriteSet.add(gameId.toString());
            }
  
            return Array.from(favoriteSet);
          });

          message.success({
            content: isFavorite ? `${gameName} has been removed from favorites!` : `${gameName} has been added to favorites!`,
            key: "custom_message",
          });
        } else {
          if (!isFavorite) {
            message.error({
              content: "Oops! Something went wrong. Couldn't update your favorites.",
              key: "custom_message",
            });
          }
        }
        setIsAddingFavorite(false);
      }
    } else {
      message.error({
        content: "Oops! You need to log in or sign up before managing favorites.",
        key: "custom_message",
      });
      if (window.innerWidth <= 768) {
        navigateToSignUp(); 
      } else {
        toggleRegModal(true);
      }
    }
  };
  // Favorite Icon End

  // xlx
  useEffect(() => {
    const filterGames = async (query) => {
      if (query.trim() !== "") {
        const filteredGamesArray = Object.values(categorizedGames)
          .flat()
          .filter((game) =>
            game.name?.["en-US"]?.toLowerCase().includes(query.toLowerCase())
          );

        // Load game icons for the filtered games (up to a maximum of 5)
        const newPromises = filteredGamesArray.slice(0, 5).map(async (game) => {
          const gameId = game.GameId;
          const provider = game.provider ? game.provider : null;
          if (!loadedIcons[gameId]) {
            try {
              let iconUrl;

              if (provider === "SA") {
                iconUrl = await getGameIcon(gameId, "sa");
              } else if (provider === "SimplePlay") {
                iconUrl = await getGameIcon(gameId, "sp");
              } else {
                iconUrl = await getGameIcon(gameId, "jili");
              }
              setLoadedIcons((prevLoadedIcons) => ({
                ...prevLoadedIcons,
                [gameId]: iconUrl,
              }));
            } catch (error) {
              console.error("Error fetching game icon:", error);
              setLoadedIcons((prevLoadedIcons) => ({
                ...prevLoadedIcons,
                [gameId]: defaultIconUrl, // Use default icon URL in case of an error
              }));
            }
          }
        });

        try {
          await Promise.all(newPromises); // Wait for the new promises to resolve
          setFilteredGames(filteredGamesArray.slice(0, 5)); // Update filtered games with a maximum of 5 games
        } catch (error) {
          console.error("Error loading game icons:", error);
          setFilteredGames([]);
        }
      } else {
        setFilteredGames([]); // If search query is empty, reset filteredGames to an empty array
      }
    };

    filterGames(search);
  }, [search, categorizedGames, loadedIcons]);

  const [hoverStates, setHoverStates] = useState(
    Array(displayedGames.length).fill(false)
  );

  // const handleMouseEnter = (index) => {
  //   const updatedHoverStates = [...hoverStates];
  //   updatedHoverStates[index] = true;
  //   setHoverStates(updatedHoverStates);
  // };

  // const handleMouseLeave = (index) => {
  //   const updatedHoverStates = [...hoverStates];
  //   updatedHoverStates[index] = false;
  //   setHoverStates(updatedHoverStates);
  // };
  const handleMouseEnter = (index) => {
    setHoverStates((prevStates) => {
      const updatedHoverStates = [...prevStates];
      updatedHoverStates[index] = true;
      return updatedHoverStates;
    });
  };

  const handleMouseLeave = (index) => {
    setHoverStates((prevStates) => {
      const updatedHoverStates = [...prevStates];
      updatedHoverStates[index] = false;
      return updatedHoverStates;
    });
  };
  return (
    <div
      className={`w-full max-w-[1800px] items-center mx-auto h-full bg-transparent relative transition-opacity duration-500 ${
        gamesLoaded ? "opacity-100" : "opacity-0"
      }`}
    >
      {messageHolder}
      {/* <div className="absolute left-0 z-[9]">
        <Image preview={false} src={gradient}/>
      </div> */}
      {/* <div className="block md:hidden fixed top-0 h-full z-0">
        <Image preview={false} src={bgWallPaper} style={{ height: "100%", objectFit: "fill"}}/>
      </div>
      <div className="md:block hidden h-full z-0 blur-sm fixed top-0">
        <Image preview={false} src={bgWallPaper1} />
      </div> */}
      <div className="h-full w-full fixed top-0 block md:hidden" />
      {/* <div className="flex flex-col first-line:justify-center items-center md:justify-start md:items-start w-full h-full pb-10 px-5 md:p-10 md:px-10 z-10"> */}
      <div className="flex flex-col justify-center items-center md:justify-center md:items-center w-full h-full pb-10 px-5 md:p-10 md:px-10 z-10">
        {/* NEW - xlx */}
        <div
          className={`relative inline-block mt-10 xl:mt-0 w-full z-10 ${
            categoriesToShow && "mt-10"
          }`}
        >
          <SearchOutlined className="absolute left-3 top-[30%] text-[#61687E] text-[20px]" />
          <input
            type="text"
            className="h-[50px] bg-[#2E3240] bg-opacity-80 text-[16px] pl-10 w-full rounded-lg"
            placeholder="Search your game..."
            style={{ color: "white", border: "none", outline: "none" }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <GameCategory onMoreGamesClick={null} selectCategory={selectCategory} selectedCategory={categoriesToShow}/>
        {/* Gaming Provider Start */}
        {categoriesToShow && (
          <GamingProvider
            selectedProvider={selectedProvider}
            providerToShow={providerToShow}
            hiddenProviders={hiddenProviders}
          />
        )}
        {/* Gaming Provider End */}

        {/* Popular Games Start */}
        {/* <div className="flex w-full justify-center items-center md:mb-10 mt-3">
          {!search && !categoriesToShow && !providerToShow && (
            <PopularGames
              allGames={allGames}
              toggleRegModal={toggleRegModal}
              navigateToSignUp={navigateToSignUp}
            />
          )}
        </div> */}
        {/* Popular Games End */}
        {providerToShow ? (
          <div className="flex items-center gap-3 z-10 mt-3">
            <Image
              src={star}
              preview={false}
              style={{ width: 45, height: 45 }}
            />
            <span className="text-textYellow font-semibold text-[22px]">
              {providerToShow === "jili"
                ? "JILI GAMES"
                : providerToShow === "SA"
                ? "SA GAMING"
                : providerToShow === "SimplePlay"
                ? "SIMPLE PLAY"
                : providerToShow}
            </span>
          </div>
        ) : (
          !categoriesToShow && (
            <div className="hidden items-center gap-3 z-10 pt-10">
              <Image
                src={star}
                preview={false}
                style={{ width: 45, height: 45 }}
              />
              <span className="text-textYellow font-semibold text-[22px]">
                All Games
              </span>
            </div>
          )
        )}
        {filteredGames && filteredGames.length > 0 ? ( //Filtered Games
          <div className={`flex flex-col w-full justify-start items-start`}>
            <div className="flex items-center gap-3">
              <span className="text-textYellow font-semibold text-[22px] py-10">
                Search Result
              </span>
            </div>
            <div className="md:flex md:flex-wrap grid grid-cols-3 justify-start gap-10">
              {filteredGames.map((game, index) => (
                // <div key={game.GameId} className="relative z-20" onClick={() => handleGameClick(game.GameId, game.name['en-US'], game.provider)}>
                <div key={game.GameId} className="relative z-20">
                  <div
                    key={game.GameId}
                    // className="w-[100px] md:w-[250px] sm:h-auto min-h-[165.95px] bg-[#1F232E] flex flex-col items-center justify-center p-5 gap-5 rounded-[10px] cursor-pointer hover:-translate-y-3 transition-all ease-in duration-100 z-[10]"
                    // className="w-[100px] md:w-[250px] sm:h-auto min-h-[165.95px] md:min-h-[296.4px] flex flex-col items-center justify-center gap-3 rounded-[10px] transition-all ease-in duration-150 z-[10]"
                    className={`${
                      isIphoneSE ? "w-[90px]" : "w-[100px]"
                    } md:w-[250px] relative sm:h-auto min-h-[135.95px] md:min-h-[296.4px] flex flex-col items-center justify-center p-0 gap-3 md:gap-5 rounded-[10px] z-[10]`}
                    // onClick={() => handleGameClick(game.GameId, game.name['en-US'], game.provider)}
                  >
                    {loadingGames[game.GameId] ? (
                      <Spin
                        size="large"
                        indicator={
                          <Loading3QuartersOutlined
                            style={{ fontSize: 24 }}
                            spin
                          />
                        }
                      />
                    ) : (
                      <>
                        {/* {renderGameIcon(game.GameId, game.provider ? game.provider : null)} */}
                        <div className="relative block justify-center items-center group">
                          {renderGameIcon(
                            game.GameId,
                            game.provider ? game.provider : null
                          )}
                          <div
                            className={`w-full h-full absolute top-0 ${
                              hoverStates[game.GameId]
                                ? "opacity-70"
                                : "opacity-0"
                            } bg-black transition-opacity duration-150`}
                            onMouseEnter={() => handleMouseEnter(game.GameId)}
                            onMouseLeave={() => handleMouseLeave(game.GameId)}
                          ></div>
                          <div
                            className="absolute flex justify-center items-center top-0 right-0 bottom-0 left-0 opacity-0 group-hover:opacity-100 transition-opacity duration-150"
                            onMouseEnter={() => handleMouseEnter(game.GameId)}
                            onMouseLeave={() => handleMouseLeave(game.GameId)}
                          >
                            <span
                              className="gradient-button outlined-text px-8 py-4 rounded-full text-[15px] tracking-wide cursor-pointer font-semibold hover:scale-[1.10] transition-all ease-in"
                              onClick={() =>
                                handleGameClick(
                                  game.GameId,
                                  game.name["en-US"],
                                  game.provider
                                )
                              }
                            >
                              Play Now
                            </span>
                          </div>
                          <img
                            alt="favorite"
                            src={
                              Array.isArray(favorites) && favorites.includes(game.GameId.toString())
                                ? favoriteIconFill
                                : favoriteIcon
                            }
                            className="absolute right-2 bottom-2 w-[25px] h-[25px] md:w-[30px] md:h-[30px] cursor-pointer transform transition-transform ease-in-out hover:scale-110 hover:z-10"
                            onClick={() => handleFavoriteClick(game.GameId, game.name["en-US"])}
                          />
                        </div>
                        <div className="flex w-full flex-col gap-2 text-center items-center text-[11px] md:text-[16px]">
                          <span className="text-textYellow font-normal text-[9px] md:text-[16px] md:font-semibold">
                            {game.name["en-US"]}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : search.trim() !== "" ? ( // Check if the search query is not empty
          <div>
            <div className="flex items-center gap-3">
              <span className="text-textYellow font-semibold text-[22px] mt-5 py-10">
                No Result Found
              </span>
            </div>
          </div>
        ) : (
          // Categorized Games
          Object.entries(categorizedGames).map(([category, gamesArray]) => (
            <div
              key={category}
              className={`w-full transition-opacity duration-500`}
            >
              <div className="flex justify-between items-center w-full px-3 xl:px-2 gap-3">
                <span className="text-textYellow font-semibold text-[22px] py-10 z-10">
                  {category}
                </span>
                <button
                  className="text-textYellow font-semibold cursor-pointer z-10 p-3 rounded-md bg-transparent border border-[#FCDB9C] hover:scale-[1.10] transition-all ease-in duration-150"
                  onClick={() => handleMoreGamesClick(category)}
                >
                  More {`>`}
                </button>
              </div>
              <div
                className={`md:flex md:flex-wrap grid grid-cols-3 justify-center lg:justify-center items-center gap-5 md:gap-10`}
              >
                {/* <div
                // className={`flex flex-wrap justify-center lg:justify-center items-start gap-5 md:gap-10`}
                className={`flex flex-wrap w-full justify-center items-center md:items-start gap-5 md:gap-10`}
              > */}
                {gamesArray
                  .slice(0, displayedGames[category] || 5)
                  .map((game, index) => (
                    // <div key={game.GameId} className="relative z-20" onClick={() => handleGameClick(game.GameId, game.name['en-US'], game.provider)}>
                    <div key={game.GameId} className="relative z-20">
                      <div
                        key={game.GameId}
                        // className="w-[100px] md:w-[250px] sm:h-auto min-h-[165.95px] md:min-h-[296.4px] bg-[#1F232E] flex flex-col items-center justify-center p-5 gap-5 rounded-[10px] cursor-pointer hover:-translate-y-3 transition-all ease-in duration-100 z-[10]"
                        // Old Width
                        // className={`${
                        //   isIphoneSE ? "w-[90px]" : "w-[100px]"
                        // } md:w-[250px] sm:h-auto min-h-[135.95px] md:min-h-[296.4px] flex flex-col items-center justify-center p-0 gap-3 md:gap-5 rounded-[10px] z-[10]`}

                        // New Width
                        className={`${
                          isIphoneSE ? "w-[90px]" : "w-[100px]"
                        } md:w-[250px] relative sm:h-auto min-h-[135.95px] md:min-h-[296.4px] flex flex-col items-center justify-center p-0 gap-3 md:gap-5 rounded-[10px] z-[10]`}
                        // onClick={() => handleGameClick(game.GameId, game.name['en-US'], game.provider)}
                      >
                        {loadingGames[game.GameId] ? (
                          <Spin
                            size="large"
                            indicator={
                              <Loading3QuartersOutlined
                                style={{ fontSize: 24 }}
                                spin
                              />
                            }
                          />
                        ) : (
                          <>
                            {/* {renderGameIcon(game.GameId, game.provider ? game.provider : null)} */}
                            <div className="relative block justify-center items-center group">
                              {renderGameIcon(
                                game.GameId,
                                game.provider ? game.provider : null
                              )}
                              <div
                                className={`w-full h-full absolute top-0 ${
                                  hoverStates[game.GameId]
                                    ? "opacity-70"
                                    : "opacity-0"
                                } bg-black transition-opacity duration-150`}
                                onMouseEnter={() =>
                                  handleMouseEnter(game.GameId)
                                }
                                onMouseLeave={() =>
                                  handleMouseLeave(game.GameId)
                                }
                              ></div>
                              <div
                                className="absolute flex justify-center items-center top-0 right-0 bottom-0 left-0 opacity-0 group-hover:opacity-100 transition-opacity duration-150"
                                onMouseEnter={() =>
                                  handleMouseEnter(game.GameId)
                                }
                                onMouseLeave={() =>
                                  handleMouseLeave(game.GameId)
                                }
                              >
                                <span
                                  className="gradient-button outlined-text px-8 py-4 rounded-full text-[15px] tracking-wide cursor-pointer font-semibold hover:scale-[1.10] transition-all ease-in"
                                  onClick={() =>
                                    handleGameClick(
                                      game.GameId,
                                      game.name["en-US"],
                                      game.provider
                                    )
                                  }
                                >
                                  Play Now
                                </span>
                              </div>
                              {/* Favorite Icon */}
                              <img
                                alt="favorite"
                                src={
                                  Array.isArray(favorites) && favorites.includes(game.GameId.toString())
                                    ? favoriteIconFill
                                    : favoriteIcon
                                }
                                className="absolute right-3 bottom-3 w-[25px] h-[25px] md:w-[30px] md:h-[30px] cursor-pointer transform transition-transform ease-in-out hover:scale-110 hover:z-10"
                                onClick={() => handleFavoriteClick(game.GameId, game.name["en-US"])}
                              />
                              {/* Favorite Icon */}
                            </div>
                            <div className="flex w-full flex-col flex-none gap-2 text-center items-center text-[11px] md:text-[16px]">
                              <span className="text-textYellow font-normal text-[9px] md:text-[16px] md:font-semibold">
                                {game.name["en-US"]}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                {/* Old More Button */}
                {/* {gamesArray.length > (displayedGames[category] || 5) && (
                  <div
                    key={`${category}-more-games`}
                    // className="w-[100px] md:w-[250px] sm:h-auto min-h-[135.95px] md:min-h-[296.4px] bg-[#1F232E] flex flex-col items-center justify-center p-5 gap-5 rounded-[10px] cursor-pointer hover:-translate-y-3 transition-all ease-in duration-100 z-[10]"
                    className={`${
                      isIphoneSE ? "w-[90px]" : "w-[100px]"
                    } md:w-[200px] md:h-[200px] min-h-[105px] lg:w-[250px] md:min-h-[250.4px] bg-gradient-to-t from-gray-900 to-gray-700 border border-solid border-gray-600 flex flex-col mt-[4px] items-center justify-center p-10 rounded-lg cursor-pointer lg:hover:-translate-y-3 transition-all ease-in duration-100 z-10`}
                    onClick={() =>
                      handleMoreGamesClick(category, displayedGames)
                    }
                  >
                    <span className="text-white text-center font-normal md:text-[16px] md:font-semibold">
                      {loadingMoreGames[category] ? (
                        <Spin size="large" />
                      ) : (
                        "More..."
                      )}
                    </span>
                  </div>
                )} */}
                {/* Old More Button */}
              </div>
            </div>
          ))
        )}
        {/* xlx */}
      </div>
    </div>
  );
};

export default AllGames;
