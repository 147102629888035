import React, { useState, useEffect } from 'react';
// import jiliImage from '../../../../assets/resources/gamingProviderImage/jili-preview.webp';
// import saImage from '../../../../assets/resources/gamingProviderImage/sa-gaming-preview.webp';
// import spImage from '../../../../assets/resources/gamingProviderImage/simpleplay-preview.webp';
import jiliImage from '../../../../assets/resources/images/jili_icon.webp';
import saImage from '../../../../assets/resources/images/sa_icon.webp';
import spImage from '../../../../assets/resources/images/simple_play_icon.webp';
import { Image } from 'antd';

const GamingProvider = ({ selectedProvider, providerToShow, hiddenProviders }) => {

    const [providersToHide, setProvidersToHide] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        setProvidersToHide(hiddenProviders);
        if(providerToShow === null){
            setSelectedItem(null);
        }
    }, [providerToShow, hiddenProviders]);

    const items = [
        { image: jiliImage, provider: 'jili', width: '80%' },
        { image: saImage, provider: 'SA', width: '80%' },
        { image: spImage, provider: 'SimplePlay', width: '80%' },
    ];

    const visibleItems = items.filter(item => {
        if (Array.isArray(providersToHide)) {
            return !providersToHide.includes(item.provider.toLowerCase());
        }
        return true;
    });

    const handleItemClick = (index) => {
        if (selectedItem === index) {
            setSelectedItem(null);
            selectedProvider(null); // If the same item is clicked twice, reset to null
        } else {
            setSelectedItem(index);
            selectedProvider(visibleItems[index].provider);
        }
    };
    
 
    return (
        <div className='flex justify-center text-white w-full gap-2 mt-[40px] items-center'>
            {visibleItems.map((item, index) => (
                <div
                    key={index}
                    className={`h-[50px] md:h-[90px] rounded-md w-[200px] flex justify-center items-center hover:border-1 duration-150 cursor-pointer border-solid ${selectedItem === index ? 'border-5 border-yellow-500' : 'border-2 border-gray-500'} hover:border-yellow-500 bg-[#141720]`}
                    onClick={() => handleItemClick(index)}
                >
                    <Image
                        src={item.image}
                        width={item.width}
                        style={{ objectFit: 'cover'}}
                        preview={false}
                    />
                </div>
            ))}
        </div>
    );
};

export default GamingProvider;