import heart from "../assets/resources/icons/mwIcons/MONEYWIN ICONS-HEART-FILL.png";
import slot from "../assets/resources/icons/mwIcons/MONEYWIN ICONS-SLOT-01.png";
import casino from "../assets/resources/icons/mwIcons/MONEYWIN ICONS-CASINO-01.png";
import fishing from "../assets/resources/icons/mwIcons/MONEYWIN ICONS-FISHING-01.png";
import poker from "../assets/resources/icons/mwIcons/MONEYWIN ICONS-POKER-01.png";
import table from "../assets/resources/icons/mwIcons/MONEYWIN ICONS-TABLE-01.png";
import browsing from "../assets/resources/icons/mwIcons/MONEYWIN ICONS-BROWSE.png";
import app from "../assets/resources/icons/mwIcons/MONEYWIN ICONS-APP.png";
import deposit1 from "../assets/resources/icons/mwIcons/MONEYWIN ICONS-DEPOSIT.png";
import account from "../assets/resources/icons/mwIcons/MONEYWIN ICONS-ACCOUNT.png";
import customercare from "../assets/resources/icons/mwIcons/MONEYWIN ICONS-CUSTOMER-CARE.png";
import bethistoryIcon from "../assets/resources/icons/mwIcons/bet_history.png";
import cashInIcon from "../assets/resources/icons/mwIcons/cash_in.png";
import cashOutIcon from "../assets/resources/icons/mwIcons/cash-out.png";
import profileIcon from "../assets/resources/icons/mwIcons/profile.png";
import securityIcon from "../assets/resources/icons/mwIcons/security.png";
import dashboardIcon from "../assets/resources/icons/mwIcons/dashboard.png";
import teamManagementIcon from "../assets/resources/icons/mwIcons/team-mgmt.png";
import teamBettingIcon from "../assets/resources/icons/mwIcons/team-betting.png";
import transRecords from "../assets/resources/icons/mwIcons/transaction-records.png";
// import commissionReport from '../assets/resources/icons/mwIcons/commission_report.webp';
import coinLogo from "../assets/resources/icons/mwIcons/mw-cash-small.webp";
import { Image } from "antd";
export const games = [
  { title: "Favorites", icon: heart },
  { title: "Slot", icon: slot },
  { title: "Casino", icon: casino },
  { title: "Fishing", icon: fishing },
  { title: "Poker", icon: poker },
  { title: "Table", icon: table },
];

export const games1 = [
  { title: "Favorites", icon: heart },
  { title: "Slot", icon: slot },
  { title: "Casino", icon: casino },
  { title: "Fishing", icon: fishing },
  { title: "Poker", icon: poker },
  { title: "Table", icon: table },
];

export const backOffice = [
  { title: "Dashboard", icon: dashboardIcon },
  { title: "Team Management", icon: teamManagementIcon },
  { title: "Team Betting", icon: teamBettingIcon },
  //{ title: "Dividends", icon: teammanagement },
  // { title: "Commission Report", icon: commissionReport},
  { title: "Transaction Records", icon: transRecords },
  { title: "Cash-In Request", icon: cashInIcon },
  { title: "Cash-Out Request", icon: cashOutIcon },
];

export const userPanel = [
  { title: "Bet History", icon: bethistoryIcon },
  { title: "Cash-In", icon: cashInIcon },
  { title: "Cash-Out", icon: cashOutIcon },
  { title: "Profile", icon: profileIcon },
  { title: "WinCash", icon: cashInIcon },
  { title: "Payment Channel", icon: cashOutIcon },
  { title: "Security", icon: securityIcon },
  { title: "Settings", icon: profileIcon },
];

export const mobileNavItems = [
  { title: "Browse", icon: browsing },
  { title: "App", icon: app },
  { title: "Deposit", icon: deposit1 },
  { title: "Account", icon: account },
  { title: "Customer Care", icon: customercare },
];

export const steps = [
  {
    title: "Sign Up!",
    desc: "Fill out the form, register, play, and enjoy!",
    no: "01",
  },
  {
    title: "Deposit",
    desc: "Select payment method, enter amount, play.",
    no: "02",
  },
  {
    title: "Enjoy Playing",
    desc: "Enjoy a wide range of games. Let the fun begin!",
    no: "03",
  },
];

export const faqItems = [
  {
    key: 1,
    title: "What is an online casino?",
    label:
      "An online casino is a virtual platform where you can play various casino games, such as slots, table games, and card games, over the internet.",
  },
  {
    key: 2,
    title: "Is it legal to play at your online casino?",
    label:
      "Yes, our online casino operates within the legal framework of the jurisdictions it serves. Please check your local laws to ensure online gambling is permitted in your area.",
  },
  {
    key: 3,
    title: "How do I register an account?",
    label: `To register an account, simply click on the "Register" button on our website, and follow the step-by-step registration process.`,
  },
  {
    key: 4,
    title: "How can I deposit money into my casino account?",
    label:
      "You can deposit funds into your account using various payment methods, including credit/debit cards, e-wallets, bank transfers, and other options.",
  },
  {
    key: 5,
    title: "Is my financial information safe and secure?",
    label:
      "Yes, we use advanced encryption technology to protect your financial information and ensure your transactions are secure.",
  },
  {
    key: 6,
    title: "Are the games fair?",
    label:
      "All games at our online casino are tested and certified for fairness by independent auditors.",
  },
  {
    key: 7,
    title: "Can I withdraw my winnings at any time?",
    label: "Yes, you can request a withdrawal of your winnings at any time.",
  },
  {
    key: 8,
    title: "How long does it take to process withdrawals?",
    label:
      "Withdrawal processing times may vary depending on the chosen payment method. Kindly contact the Customer Support for more assistance regarding this step.",
  },
];

export const termsItems = [
  {
    title: "1. Acceptance of Terms",
    label: `By accessing and using MoneyWin (the "Website" or "Service"), you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you are not permitted to use our service.`,
  },
  {
    title: "2. Eligibility",
    label:
      "You must be at least the legal gambling age in your jurisdiction to use our services. You are responsible for complying with the laws and regulations in your jurisdiction regarding online gambling.",
  },
  {
    title: "3. Registration and Accounts",
    label:
      "You agree to provide accurate and up-to-date information during the registration process. You are responsible for maintaining the confidentiality of your account login information.",
  },
  {
    title: "4. Deposits and Withdrawals",
    label:
      "Deposits into your account are subject to minimum and maximum limits. Withdrawals are subject to processing times and may require verification. Currency conversion rates may apply for deposits and withdrawals.",
  },
  {
    title: "5. Wagering and Betting",
    label:
      "You acknowledge that you may lose the funds you wager. All bets are final and cannot be reversed.",
  },
  {
    title: "6. Bonuses and Promotions",
    label:
      "Bonuses and promotions are subject to specific terms and wagering requirements. Failure to meet these requirements may result in the forfeiture of bonus funds.",
  },
  {
    title: "7. Responsible Gambling",
    label:
      "We promote responsible gambling and offer tools to help you control your gambling activity. You can set limits on deposits, bets, and losses, or self-exclude if needed.",
  },
  {
    title: "8. Fair Play and Cheating",
    label:
      "We have strict anti-cheating measures in place, and any form of cheating is strictly prohibited. The use of automated software, bots, or any form of artificial intelligence is not allowed.",
  },
  {
    title: "9. Disputes and Complaints",
    label:
      "If you have a dispute or complaint, please contact our customer support team for resolution. If a dispute arises, our decision will be final.",
  },
  {
    title: "10. Termination of Account",
    label:
      "We reserve the right to terminate or suspend your account at our discretion. Funds in your account may be returned to you subject to any applicable fees and legal requirements.",
  },
  {
    title: "11. Changes to Terms and Conditions",
    label:
      "We may update these terms and conditions at any time. It is your responsibility to review these terms regularly.",
  },
  {
    title: "12. Limitation of Liability",
    label:
      "MoneyWin is not responsible for any loss or damage resulting from the use of our service. We are not liable for any third-party content or services linked from our website.",
  },
  {
    title: "13. Contact Information",
    label:
      "If you have questions or concerns, please contact us through the Contact Us form, or email us at support@moneywin.bet!",
  },
];

export const gamesItems = [
  {
    key: 1,
    title: "Slot",
    label:
      "Online slots are digital versions of traditional slot machines found in casinos. These games involve spinning virtual reels with various symbols, and players win based on the combinations that appear when the reels stop. The symbols, themes, and the number of reels and paylines can vary, adding diversity to the gaming experience. Online slots often feature bonus elements like free spins and multipliers, while some offer progressive jackpots that grow with each bet. The outcomes are determined by Random Number Generators (RNGs) to ensure fairness.",
  },
  {
    key: 2,
    title: "Casino",
    label:
      "These games typically include classics like blackjack, roulette, poker, and slot machines. Players can place bets, spin virtual reels, or participate in card games, aiming to win money or prizes.",
  },
  {
    key: 3,
    title: "Fishing",
    label:
      "In the context of online casinos, Fishing is a digital adaptation that blends the excitement of shooting arcade-style gameplay with the thrill of fishing. In these games, players use virtual cannons or guns to shoot at a variety of sea creatures swimming across the screen. Each target hit can result in different prizes or rewards, adding an element of skill and strategy to the traditional casino experience. These games often feature vibrant underwater graphics, diverse marine life, and a points-based system where players aim to accumulate the highest score or win monetary rewards based on their shooting accuracy. Shooting fishing games provide a dynamic and visually engaging twist to the typical casino game lineup, appealing to those seeking a more interactive and action-packed gaming experience.",
  },
  {
    key: 4,
    title: "Poker",
    label:
      "Online casino poker offers a digital platform for players to enjoy the classic card game from the comfort of their homes. Variants such as Texas Hold'em, Omaha, and Stud poker are commonly featured, each with its unique set of rules and strategies. Players can join virtual tables, interact with opponents through chat functions, and engage in thrilling rounds of betting, bluffing, and strategic decision-making.",
  },
  {
    key: 5,
    title: "Table",
    label:
      "Table games in online casinos encompass a diverse array of classic and popular games traditionally found in brick-and-mortar casinos. These include staples like blackjack, roulette, baccarat, and craps. With the advent of online gaming, players can now enjoy these table games virtually, replicating the authentic casino experience through digital interfaces. These games often feature realistic graphics, user-friendly interfaces, and various betting options to cater to different preferences. Online table games maintain the core rules and strategies of their land-based counterparts, allowing players to engage in strategic gameplay and compete against computer algorithms or other online players.",
  },
];

export const disclaimerCashInItems = [
  {
    key: 1,
    title: <span className="text-textGold font-bold">1. Minimum Deposit:</span>,
    label: (
      <span>
        <span> The minimum deposit amount is </span>
        <img
          src={coinLogo}
          alt="Coin Logo"
          style={{ width: "14px", height: "14px" }}
          className="mb-1"
        />
        <span> 100 Chips.</span>
      </span>
    ),
  },
  {
    key: 2,
    title: <span className="text-textGold font-bold">2. Payment Methods</span>,
    label: (
      <span>
        We accept payments through multiple E-Wallets, Banks, and Agent Assisted
        Methods. Please ensure that the payment method you choose is valid and
        authorized.
      </span>
    ),
  },
  {
    key: 3,
    title: <span className="text-textGold font-bold">3. Processing Time</span>,
    label: (
      <span>
        {" "}
        Deposits are generally processed instantly, but delays may occur due to
        technical issues or high transaction volumes.
      </span>
    ),
  },
  {
    key: 4,
    title: <span className="text-textGold font-bold">4. Verification</span>,
    label: (
      <span>
        {" "}
        To ensure security and compliance, we may require identity verification
        before processing your deposit.
      </span>
    ),
  },
];
export const disclaimerCashOutItems = [
  {
    key: 1,
    title: (
      <span className="text-textGold font-bold">1. Minimum Withdrawal</span>
    ),
    label: (
      <span>
        <span> The minimum withdrawal amount is </span>
        <img
          src={coinLogo}
          alt="Coin Logo"
          style={{ width: "14px", height: "14px" }}
          className="mb-1"
        />
        <span> 200 Chips.</span>
      </span>
    ),
  },
  {
    key: 2,
    title: (
      <span className="text-textGold font-bold">2. Maintaining Balance</span>
    ),
    label: (
      <span>
        {" "}
        There will be maintaining balance imposed for the accounts. This is to
        ensure that accounts will not be marked as inactive by the system.
      </span>
    ),
  },
  {
    key: 3,
    title: (
      <span className="text-textGold font-bold">3. Withdrawal Limits</span>
    ),
    label: (
      <span>
        There may be withdrawal limits imposed based on your account type,
        level, or jurisdiction.
      </span>
    ),
  },
  {
    key: 4,
    title: <span className="text-textGold font-bold">4. Processing Time</span>,
    label: (
      <span>
        {" "}
        Withdrawals are generally processed instantly but delays may occur due
        to security checks or technical issues.
      </span>
    ),
  },
  {
    key: 5,
    title: <span className="text-textGold font-bold">5. Verification</span>,
    label: (
      <span>
        {" "}
        For security reasons, we may request additional verification documents
        before approving your withdrawal request.
      </span>
    ),
  },
];
export const disclaimerGenRules = [
  {
    key: 1,
    title: <span className="text-textGold font-bold">1. Account Activity</span>,
    label: (
      <span>
        {" "}
        Your account must be in good standing, and any suspicious or fraudulent
        activity may result in the suspension of cash-in and cash-out
        privileges.
      </span>
    ),
  },
  {
    key: 2,
    title: <span className="text-textGold font-bold">2. Bonus Funds</span>,
    label: (
      <span>
        {" "}
        If you have received bonus funds, specific wagering requirements may
        apply before you can cash-out winnings associated with the bonus.
      </span>
    ),
  },
  {
    key: 3,
    title: <span className="text-textGold font-bold">3. Changes to Terms</span>,
    label: (
      <span>
        {" "}
        We reserve the right to modify these cash-in and cash-out rules at any
        time. Users will be notified of any changes via email or through the
        website.
      </span>
    ),
  },
];

export const cashOutRules = [
  {
    key: 1,
    title: (
      <div>
        <span>
          1. You should have at least{" "}
          <Image
            src={coinLogo}
            preview={false}
            style={{ width: 18, height: 18 }}
            className="mb-1"
          />{" "}
          100 chips as remaining balance. This will prevent your account from
          becoming inactive.
        </span>
      </div>
    ),
  },
  {
    key: 2,
    title: (
      <div>
        <span>
          2. If the system detects that you didn't meet the valid bets required
          for a cash-out, it will ask you for a confirmation because this action
          will charge your transaction a 5% service fee. This feature is
          implemented as a preventive measure for any money laundering acts.
        </span>
      </div>
    ),
  },
  {
    key: 3,
    title: (
      <div>
        <span>
          3. Transaction of{" "}
          <Image
            src={coinLogo}
            preview={false}
            style={{ width: 18, height: 18 }}
            className="mb-1"
          />{" "}
          5,000 chips below will have a: 250 Valid Bet requirement.
        </span>
      </div>
    ),
  },
  {
    key: 4,
    title: (
      <div>
        <span>
          4. Transaction of{" "}
          <Image
            src={coinLogo}
            preview={false}
            style={{ width: 18, height: 18 }}
            className="mb-1"
          />{" "}
          5,001-10,000 chips will have a: 500 Valid Bet requirement.
        </span>
      </div>
    ),
  },
  {
    key: 5,
    title: (
      <div>
        <span>
          5. Transaction of{" "}
          <Image
            src={coinLogo}
            preview={false}
            style={{ width: 18, height: 18 }}
            className="mb-1"
          />{" "}
          10,000 chips above will have a: 2,500 Valid Bet requirement.
        </span>
      </div>
    ),
  },
  {
    key: 6,
    title: (
      <div>
        <span>
          6. Minimum cash-out amount is{" "}
          <Image
            src={coinLogo}
            preview={false}
            style={{ width: 18, height: 18 }}
            className="mb-1"
          />{" "}
          200 chips.
        </span>
      </div>
    ),
  },
  {
    key: 7,
    title: (
      <div>
        <span>
          7. Maximum cash-out amount is{" "}
          <Image
            src={coinLogo}
            preview={false}
            style={{ width: 18, height: 18 }}
            className="mb-1"
          />{" "}
          50,000 chips.
        </span>
      </div>
    ),
  },
];

export const playerWelcomeMessages = [
  "Greetings! Ready to explore and win?",
  "Hello and welcome! Time for some thrilling bets.",
  "Greetings! Your journey begins now.",
  "Hello! Let's explore and win big.",
  "Welcome aboard! Ready to embark on an exciting winning journey?",
  "Hey there! Get set for thrilling bets and exhilarating wins.",
  "Welcome! Your journey to big wins begins right here.",
  "Hey! Let's dive in and explore the path to winning big.",
  "Greetings! Get ready to uncover thrilling wins and endless excitement.",
  "Hey there! Get set for an adventure filled with excitement and wins.",
];

export const userWelcomeMessages = ["Welcome!", "Hello there!"];

export const playerGoodbyeMessages = [
  "Goodbye! We hope to see you again soon for more betting fun.",
  "Thank you for being with us! Come back anytime for more excitement!",
  "See you later! Your next winning adventure awaits when you return.",
  "Until next time! Keep those betting strategies sharp for your return.",
  "Goodbye! May your next bets bring you great victories.",
  "Adios! We'll be here, ready for your next betting session.",
  "Thanks for playing! Your betting prowess is always welcome here.",
  "See you soon! The thrill of betting will be waiting for your return.",
];

export const userLogoutMessages = [
  "Until next time!",
  "Goodbye!",
  "See you soon!",
];
